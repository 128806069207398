<template>
  <div class="app-container">
    <el-row class="headerClass">
      <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">服务包管理</el-col>
      <el-col :span="20">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" style="float: right">
          <el-form-item label="服务包名称" prop="name">
<!--            <el-autocomplete-->
<!--              v-model="queryParams.name"-->
<!--              :fetch-suggestions="querySearchAsync"-->
<!--              placeholder="请输入服务包名称/代码/拼音/五笔码"-->
<!--              @select="handleQuery"-->
<!--              clearable-->
<!--              size="small"-->
<!--              value-key="name"-->
<!--              style="width: 300px;height: 36px!important;line-height: 36px;"-->
<!--              @keydown.enter.native="handleQuery"-->
<!--            ></el-autocomplete>-->
            <el-input v-model="queryParams.name"
                   placeholder="请输入服务包名称"
                      clearable
                   style="width: 300px;height: 36px!important;line-height: 36px;"/>
          </el-form-item>

          <el-form-item label="状态" prop="status">
            <el-select
              v-model="queryParams.status"
              placeholder="状态"
              clearable
              size="small"
              class="statusClass"
              style="width: 100px"
            >
              <el-option
                v-for="dict in statusOptions"
                :key="'statusOptions'+dict.conceptId"
                :label="dict.conceptVal"
                :value="dict.conceptId"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery()">检索</el-button>
            <el-button icon="el-icon-circle-plus-outline" size="small" class="addClass" @click="handleAdd">新增</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <el-table
      v-loading="loading"
      border
      stripe
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      :data="roleList">
      <el-table-column label="ID" prop="packId" width="120" />
      <el-table-column label="服务包名称" prop="packName" :show-overflow-tooltip="true"/>
      <el-table-column label="描述" prop="remark" :show-overflow-tooltip="true"/>
      <el-table-column label="状态" align="center" width="100">s
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            active-value="0"
            inactive-value="1"
            @change="handleStatusChange(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="120">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleUpdate(scope.row)"
          >编辑</el-button>
          <el-button
            size="mini"
            type="text"
            style="color: #FF3333;"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改角色配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="服务包名称" prop="packName">
          <el-input v-model="form.packName" placeholder="请输入服务包名称" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.remark" placeholder="请输入描述"
                    type="textarea"
                    :rows="2"/>
        </el-form-item>
        <el-form-item label="分类" prop="packType">
          <el-radio-group v-model="form.packType">
            <el-radio
              v-for="dict in packTypeOptions"
              :key="'packTypeOptions'+dict.value"
              :label="dict.value"
              :value="dict.value"
            >{{dict.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio
              v-for="dict in statusOptions"
              :key="'statusOptions'+dict.conceptId"
              :label="dict.conceptId"
              :value="dict.conceptId"
            >{{dict.conceptVal}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="服务包内容">
          <el-checkbox v-model="menuExpand" @change="handleCheckedTreeExpand($event, 'menu')">展开/折叠</el-checkbox>
          <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event, 'menu')">全选/全不选</el-checkbox>
          <el-checkbox v-model="form.menuCheckStrictly" @change="handleCheckedTreeConnect($event, 'menu')">父子联动</el-checkbox>
          <el-tree
            class="tree-border"
            :data="menuOptions"
            show-checkbox
            ref="menu"
            node-key="menuId"
            :check-strictly="!form.menuCheckStrictly"
            empty-text="加载中，请稍后"
            :props="defaultProps"
          ></el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { exportRole, dataScope, changeRoleStatus } from "@/api/system/role";
import { listPack, getPack, addPack, delPack } from "@/api/system/servicePackageManagement";
// import { treeselect as menuTreeselect, roleMenuTreeselect } from "@/api/system/menu";
import { listMenu as menuTreeselect, roleMenuTreeselect } from "@/api/system/menu";
// import { listMenu } from "@/api/system/menu";
import { treeselect as deptTreeselect, roleDeptTreeselect } from "@/api/system/dept";
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'

export default {
  name: "Role",
  data() {
    return {
      conceptConst,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 角色表格数据
      roleList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      // 数据范围选项
      dataScopeOptions: [
        {
          value: "1",
          label: "全部数据权限"
        },
        {
          value: "2",
          label: "自定数据权限"
        },
        {
          value: "3",
          label: "本部门数据权限"
        },
        {
          value: "4",
          label: "本部门及以下数据权限"
        },
        {
          value: "5",
          label: "仅本人数据权限"
        }
      ],
      // 服务包类型
      packTypeOptions: [
        {
          value: "1",
          label: "医疗机构"
        },
        {
          value: "2",
          label: "卫计委"
        },
        {
          value: "3",
          label: "药企"
        },
        {
          value: "4",
          label: "其他"
        }
      ],
      // 菜单列表
      menuOptions: [],
      // 部门列表
      deptOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        status: undefined
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: "children",
        label: "menuName"
      },
      // 表单校验
      rules: {
        packName: [
          { required: true, message: "服务包名称不能为空", trigger: "blur" }
        ],
        packType: [
          { required: true, message: "分类不能为空", trigger: "change" }
        ],
        status: [
          { required: true, message: "状态不能为空", trigger: "change" }
        ]
      }
    };
  },
  created() {
    this.getList();
    //获取状态数据字典
    getConcept(conceptConst.PUB_DATA_STATUS, 0).then(result => {
      this.statusOptions = result.map((item) =>{
        item.conceptId = item.conceptId.toString()
        return item
      })
      // console.log(this.statusOptions)
    })
    // this.getDicts("sys_normal_disable").then(response => {
    //   this.statusOptions = response.data;
    // });
  },
  methods: {
    /** 查询角色列表 */
    getList() {
      this.loading = true;
      listPack(this.queryParams).then(
        response => {
          // console.log(response.data.rows)
          this.roleList = response.data.rows;
          this.total = response.data.total;
          this.loading = false;
        }
      );
    },
    /** 查询菜单树结构 */
    async getMenuTreeselect() {

      let response = await menuTreeselect()
      if (response.code === 200) {
        // console.log(response)
        this.menuOptions = [];
        const menu = { menuId: 0, menuName: '主类目', children: [] };
        menu.children = this.handleTree(response.data, "menuId");
        console.log(menu)
        console.log(menu.children)
        this.menuOptions = menu.children
      }
    },
    /** 查询部门树结构 */
    getDeptTreeselect() {
      deptTreeselect().then(response => {
        this.deptOptions = response.data;
      });
    },
    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    // 所有部门节点数据
    getDeptAllCheckedKeys() {
      // 目前被选中的部门节点
      let checkedKeys = this.$refs.dept.getCheckedKeys();
      // 半选中的部门节点
      let halfCheckedKeys = this.$refs.dept.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    /** 根据角色ID查询菜单树结构 */
    getRoleMenuTreeselect(packId) {
      return roleMenuTreeselect(packId).then(response => {
        this.menuOptions = response.data.menus;
        return response;
      });
    },
    /** 根据角色ID查询部门树结构 */
    getRoleDeptTreeselect(roleId) {
      return roleDeptTreeselect(roleId).then(response => {
        this.deptOptions = response.data.depts;
        return response;
      });
    },
    // 角色状态修改
    handleStatusChange(row) {
      let text = row.status === '0' ? "启用" : "停用";
      this.$confirm('确认要"' + text + '""' + row.roleName + '"角色吗?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return changeRoleStatus(row.roleId, row.status);
      }).then(() => {
        this.msgSuccess(text + "成功");
      }).catch(function() {
        row.status = row.status === '0' ? '1' : '0';
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false;
      this.reset();
    },
    // 表单重置
    reset() {
      if (this.$refs.menu != undefined) {
        this.$refs.menu.setCheckedKeys([]);
      }
      this.menuExpand = false,
        this.menuNodeAll = false,
        this.deptExpand = true,
        this.deptNodeAll = false,
        this.form = {
          packId: undefined,
          packName: undefined,
          packType: '1',
          status: '0',
          menuIdList: [],
          menuCheckStrictly: true,
          deptCheckStrictly: true,
          remark: undefined
        };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // // 多选框选中数据
    // handleSelectionChange(selection) {
    //   this.ids = selection.map(item => item.roleId)
    //   this.single = selection.length!=1
    //   this.multiple = !selection.length
    // },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value, type) {
      if (type == 'menu') {
        let treeList = this.menuOptions;
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.menu.store.nodesMap[treeList[i].menuId].expanded = value;
        }
      } else if (type == 'dept') {
        let treeList = this.deptOptions;
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.dept.store.nodesMap[treeList[i].menuId].expanded = value;
        }
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value, type) {
      if (type == 'menu') {
        this.$refs.menu.setCheckedNodes(value ? this.menuOptions: []);
      } else if (type == 'dept') {
        this.$refs.dept.setCheckedNodes(value ? this.deptOptions: []);
      }
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value, type) {
      if (type == 'menu') {
        this.form.menuCheckStrictly = value ? true: false;
      } else if (type == 'dept') {
        this.form.deptCheckStrictly = value ? true: false;
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.getMenuTreeselect();
      this.open = true;
      this.title = "新增服务包";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      console.log(row)
      this.reset();
      const packId = row.packId
      let obj = {}
      obj.packId = packId
      getPack(obj).then( async response => {
        // console.log(response)
        this.form = response.data;
        this.open = true;
          await this.getMenuTreeselect()
          console.log(this.menuOptions)
          let checkedKeys = response.data.menuIdList
          checkedKeys.forEach((v) => {
            this.$nextTick(()=>{
              this.$refs.menu.setChecked(v, true ,false);
            })
          })
        this.title = "编辑服务包";
      });
    },
    /** 选择角色权限范围触发 */
    dataScopeSelectChange(value) {
      if(value !== '2') {
        this.$refs.dept.setCheckedKeys([]);
      }
    },
    /** 分配数据权限操作 */
    handleDataScope(row) {
      this.reset();
      const roleDeptTreeselect = this.getRoleDeptTreeselect(row.roleId);
      getPack(row.roleId).then(response => {
        this.form = response.data;
        this.openDataScope = true;
        this.$nextTick(() => {
          roleDeptTreeselect.then(res => {
            this.$refs.dept.setCheckedKeys(res.data.checkedKeys);
          });
        });
        this.title = "分配数据权限";
      });
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.packId != undefined) {
            this.form.menuIdList = this.getMenuAllCheckedKeys();
            addPack(this.form).then(response => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            // console.log(this.form)
            this.form.menuIdList = this.getMenuAllCheckedKeys();
            // this.form.status = this.form.status.toString();
            addPack(this.form).then(response => {
              this.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 提交按钮（数据权限） */
    submitDataScope: function() {
      if (this.form.packId != undefined) {
        this.form.deptIds = this.getDeptAllCheckedKeys();
        dataScope(this.form).then(response => {
          this.msgSuccess("修改成功");
          this.openDataScope = false;
          this.getList();
        });
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const packId = row.packId
      this.$confirm('是否确认删除服务包编号为"' + packId + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        let obj = {}
        obj.packId = packId
        return delPack(obj);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有角色数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return exportRole(queryParams);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>
